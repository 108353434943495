import type { SectionForHashType } from '../type';
import type { Section } from '../../common/utils/types';

import hash from 'object-hash';

import { sentryCaptureException } from '../../common/use-cases/sentry';
import { COL_TAG, SECTION_TAG } from '../../common/utils/const';
import { convertComponentToJSON, getElementsTagInComponent } from '../../common/utils/section/component';
import type { SaveError } from '../type';

export const logSaveError = (funcName: string, message: string, data: object) => {
  sentryCaptureException(funcName, message, data, {
    level: 'error',
    tag: {
      key: 'Modules',
      value: 'Save',
    },
  });
};

export const hashSection = (section: SectionForHashType, ignoreCID?: boolean) => {
  const shouldSetCIDToUndefined = section.isThemeSection || section.isShopifySection || ignoreCID;
  return hash.MD5(
    JSON.stringify({
      id: section.id,
      cid: shouldSetCIDToUndefined ? undefined : section.cid,
      component: section.component,
      name: section.name,
      display: section?.isShopifySection ? section?.isShopifyVisibility : section?.display,
    }),
  );
};

export const isUpdatableSection = (section: Section) => {
  return (
    !(section.isThemeSection || section.isCustom || section.isShopifySection) &&
    section.id &&
    isValidSectionData(section)
  );
};

export const isImageComponent = (section: Section) => /"tag"\s*:\s*"ImageDetection"/.test(section.component ?? '');

export const getStringUniqueTags = (tags: string[]) => {
  return [...new Set(tags)].filter((value: string) => value !== SECTION_TAG && value !== COL_TAG).join(',');
};

export const isInsertFromLibrarySection = (section: Section) => {
  return section.importFromLibraryType === 'library_sections';
};

export const isInsertFromLibraryTemplate = (section: Section) => {
  return section.importFromLibraryType === 'library_templates';
};

export const createNewSectionInput = (section: Section) => {
  return {
    cid: section.cid,
    name: section.name,
    component: section.component,
    display: section.display,
    elementNames: getStringUniqueTags(
      getElementsTagInComponent(section.component ? JSON.parse(section.component) : '{}', []),
    ),
  };
};

export const createUpdateSectionInput = (section: Section) => {
  return {
    cid: section.cid,
    name: section.name,
    component: section.component,
    display: section.display,
    clearDeletedAt: true,
    elementNames: getStringUniqueTags(
      getElementsTagInComponent(section.component ? JSON.parse(section.component) : '{}', []),
    ),
  };
};

export const excludeThemeSection = (section: Section) => !section.isThemeSection;

export const minifyError = (error: unknown) => JSON.stringify(error).substring(0, 30);

export const isSaveErrorObject = (error: unknown): error is SaveError => {
  return !!error && typeof error === 'object' && 'response' in error;
};

export const isValidSectionData = (section: Section) => {
  if (section.name == 'SectionLoading') return null;
  const component = section?.component;
  if (!component) return null;

  const jsonComponent = convertComponentToJSON(component);
  if (!jsonComponent || !jsonComponent.uid || !jsonComponent.tag) return null;

  return true;
};
