import useShopStore from '@/stores/shop';
import { useRoute } from 'vue-router';
import type { ComputedRef } from 'vue';

export const useMode = () => {
  const route = useRoute();
  const shopStore = useShopStore();
  const isInstant = computed(() => ['InstantPageViews', 'InstantPages'].includes(route?.name?.toString() || ''));

  const isGemPagesV5 = computed(() =>
    [
      'HomePages',
      'LandingPages',
      'ProductPages',
      'CollectionPages',
      'ArticlePages',
      'InstantHyperlinkEdit',
      'InstantHyperlinkNew',
    ].includes(route?.name?.toString() || ''),
  );

  const isEditor = computed(() => ['Editor', 'ThemeSectionEditor'].includes(route?.name?.toString() || ''));
  const isThemeSectionEditor = computed(() => ['ThemeSectionEditor'].includes(route?.name?.toString() || ''));

  const connectedType = computed(() => shopStore.getConnectedType);

  const editorConnectedType = computed(() => route?.query?.type?.toString() || 'gempagesv7') as ComputedRef<
    'editor' | 'instant' | 'gempagesv7' | 'storefront'
  >;

  const editorImageToLayout = computed(() => !isThemeSectionEditor.value);

  const updateConnectedType = () => {
    if (isEditor.value) {
      shopStore.setConnectedType('editor');
      return;
    }
    if (isInstant.value) {
      shopStore.setConnectedType('instant');
      return;
    }
    if (isGemPagesV5.value) {
      shopStore.setConnectedType('gempagesv7');
      return;
    }
    shopStore.setConnectedType('storefront');
  };

  updateConnectedType();

  return {
    connectedType,
    editorConnectedType,
    updateConnectedType,
    editorImageToLayout,
    isThemeSectionEditor,
  };
};
