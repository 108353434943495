import type { RouteRecordRaw } from 'vue-router';

const editorRoutes: RouteRecordRaw[] = [
  {
    path: '/editor/section/:themeId',
    component: () => import('./layouts/Index.vue'),
    children: [
      {
        path: ':themeSectionId',
        name: 'ThemeSectionEditor',
        component: () => import('./modules/common/pages/Editor.vue'),
      },
    ],
  },
  {
    path: '/editor/:themeId', // full: //app.example.com/editor/{themeId}/{pageId}
    component: () => import('./layouts/Index.vue'),
    children: [
      {
        path: ':pageId',
        name: 'Editor',
        component: () => import('./modules/common/pages/Editor.vue'),
      },
    ],
  },
];

export default editorRoutes;
